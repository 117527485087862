import React, {useState} from 'react';
import MensajesIngles from './../lang/en-US.json';
import MensajesEspañol from './../lang/es-ES.json';
import {IntlProvider} from 'react-intl';

const langContext = React.createContext();

const LangProvider = ({children}) => {
    let localePorDefecto;
    let MensajesPorDefecto;
    var lang = localStorage.getItem('lang')

    if (lang) {
        localePorDefecto = lang
        if (lang === 'es-ES') {
            MensajesPorDefecto = MensajesEspañol
            document.querySelector("body").classList.add("es")
            document.querySelector("body").classList.remove("en")
        }else if (lang === 'en-US') {
            /*
            MensajesPorDefecto = MensajesIngles
            document.querySelector("body").classList.add("en")
            document.querySelector("body").classList.remove("es")
            */
            MensajesPorDefecto = MensajesEspañol
            document.querySelector("body").classList.add("es")
            document.querySelector("body").classList.remove("en")
        }
    }else{
        localePorDefecto = 'es-ES'
        MensajesPorDefecto = MensajesEspañol
        document.querySelector("body").classList.add("es")
        document.querySelector("body").classList.remove("en")
    }


    const [mensajes, establecerMensajes] = useState(MensajesPorDefecto);
    const [locale, establecerLocale] = useState(localePorDefecto);

    const establecerLenguaje = (lenguaje) =>{
        switch(lenguaje){
            case 'en-US':
                establecerMensajes(MensajesIngles);
                establecerLocale('en-US');
                localStorage.setItem('lang','en-US')
                break;
            case 'es-ES':
                establecerMensajes(MensajesEspañol);
                establecerLocale('es-ES');
                localStorage.setItem('lang','es-ES')
                break;
            default:
                establecerMensajes(MensajesEspañol);
                establecerLocale('es-ES');
                localStorage.setItem('lang','es-ES')
        }
    }   

    return (
        <langContext.Provider value={{establecerLenguaje: establecerLenguaje}}>
            <IntlProvider locale={locale} messages={mensajes}>
                {children}
            </IntlProvider>
        </langContext.Provider>
    );
}

export {LangProvider, langContext};