import React, { useEffect, useRef } from 'react';
import nosotrosimg from '../images/nosotros.webp'
import nosotrosimg1 from '../images/nosotros1.webp'
import { FormattedMessage } from 'react-intl';

const Nosotros = () => {

    function close() {
        document.querySelector("#popFormulario").classList.toggle("open");
    }

    function myFunction() {
        var listaImagenes = document.querySelectorAll(".nosotros .imageDistorcion");
        var tiempoTransicion = 70;

        for (let i = 0; i < listaImagenes.length; i++) {
            setTimeout(() => {
                listaImagenes[i].style.opacity = 1;
            }, i * tiempoTransicion);
            if (i !== listaImagenes.length - 1) {
                setTimeout(() => {
                    listaImagenes[i + 1].style.opacity = 1;
                    setTimeout(() => {
                        listaImagenes[i].style.opacity = 0;
                    }, (i) * 20);
                }, (i + 0.2) * tiempoTransicion);
            } else {
                listaImagenes[i].style.opacity = 0;
            }
        }
    }

    function distorcion() {
        myFunction()
    }

    const mediaQuery = window.matchMedia('(max-width: 1200px)');

    if (mediaQuery.matches) {
        setTimeout(function () {
            myFunction();
            setInterval(function () {
                myFunction();
            }, 5000);
        }, 2000);
    } else {
    }


    setTimeout(() => {
        var h2Element = document.querySelectorAll('.nosotros h2');
        var spanElement = document.querySelectorAll('.nosotros h2 span');

        for (let i = 0; i < h2Element.length; i++) {
            var textoH2 = h2Element[i].textContent;
            var palabras = textoH2.split(' ');
            var nuevoContenido = '';
            palabras.forEach(function (palabra) {
                h2Element[i].classList.add('mostrar');
                nuevoContenido += '<span>' + palabra + '</span> ';
            });
            h2Element[i].innerHTML = nuevoContenido.trim();
        }
    }, 100);

    const targetRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Umbral de visibilidad del 50%
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        var h2spanElement = document.querySelectorAll('.nosotros h2 span');
                        var tiempoTransicion = 70;
                        for (let i = 0; i < h2spanElement.length; i++) {
                            setTimeout(() => {
                                h2spanElement[i].classList.add('mostrar');
                            }, i * tiempoTransicion);
                        }
                    }, 100);

                    setTimeout(() => {
                        var h2activo = document.querySelectorAll('.nosotros h2');
                        for (let i = 0; i < h2activo.length; i++) {
                            h2activo[i].classList.add("active");
                        }
                    }, 1000);
                }
            });
        }, options);

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, []);


    return <>
        <section className="nosotros" id="nosotros">
            <div className="container">
                <div className="part1" onMouseEnter={distorcion}>
                    <img src={nosotrosimg1} alt="img2" className='imageDistorcion img1' />
                    <img src={nosotrosimg1} alt="img2" className='imageDistorcion img2' />
                    <img src={nosotrosimg1} alt="img2" className='imageDistorcion img3' />
                    <img src={nosotrosimg1} alt="img2" className='imageDistorcion img4' />
                    <img src={nosotrosimg1} alt="img2" className='imageDistorcion img5' />
                    <img src={nosotrosimg1} alt="img2" className='imageDistorcion img6' />
                    <img src={nosotrosimg1} alt="img2" className='imageDistorcion img7' />
                    <img src={nosotrosimg1} alt="img2" className='imageDistorcion img8' />
                    <img src={nosotrosimg1} alt="img2" className='imageDistorcion img9' />
                    <img src={nosotrosimg1} alt="img2" className='imageDistorcion img10' />
                </div>
                <div className="part2" ref={targetRef}>
                    <h2 className='mensajeEspanol'>Lo clásico nunca pasa de moda</h2>
                    <h2 className='ocultar mensajeIngles'>Classic never goes out of style</h2>
                    <p data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true"><FormattedMessage id="nosotros_texto" /></p>
                    <div class="buttonColor" data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true">
                        <a href='#home'><FormattedMessage id="button_contacto" /></a>
                    </div>
                    <button onClick={close} data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true"><FormattedMessage id="button_contacto" /></button>
                </div>
            </div>
        </section>
    </>
};

export default Nosotros;