import React from 'react';
import perfil1 from '../images/alonso.jpg'
import perfil2 from '../images/fran.jpg'
import linkedin1 from '../icons/linkedin1.svg'
import flecha from '../icons/flechita.svg'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
gsap.registerPlugin(ScrollTrigger)

const Equipo = () => {

    const parentRef = useRef(null);
    const movingElementRef = useRef(null);
    const targetRef = useRef(null);

    useEffect(() => {
        const parentElement = parentRef.current;
        const movingElement = movingElementRef.current;

        ScrollTrigger.create({
            trigger: parentElement,
            start: 'top 29%',
            end: 'bottom 47.7%',
            scrub: true,
            //markers: true,
            onUpdate: (self) => {
                const progress = self.progress;
                const parentHeight = parentElement.offsetHeight;
                const elementHeight = movingElement.offsetHeight;
                const maxTranslation = parentHeight - elementHeight;

                gsap.set(movingElement, { y: maxTranslation * progress * 1 });
            },
        });
    }, []);
    setTimeout(() => {
        var h2Element = document.querySelectorAll('.equipo h2');
        var h3Element = document.querySelectorAll('.equipo .part1-1 h3');

        for (let i = 0; i < h2Element.length; i++) {            
            h2Element[i].classList.add('mostrar');
            /*
            var textoH2 = h2Element[i].textContent;
            var palabras = textoH2.split(' ');
            var nuevoContenido = '';

            palabras.forEach(function (palabra, index) {
                nuevoContenido += '<span>' + palabra + '</span>';

                if (index === 0) {
                    nuevoContenido += '<br>';
                } else {
                    nuevoContenido += ' ';
                }
            });

            h2Element[i].innerHTML = nuevoContenido.trim();
            */
        }

        
        for (let i = 0; i < h3Element.length; i++) {
            h3Element[i].classList.add('mostrar');
            /*
            var textoH3 = h3Element[i].textContent;
            var palabras2 = textoH3.split(' ');
            var nuevoContenido2 = '';
            palabras2.forEach(function (palabra2, index) {
                nuevoContenido2 += '<span>' + palabra2 + '</span> ';
            });
            h3Element[i].innerHTML = nuevoContenido2.trim();            
            */
        }

    }, 100);
    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.3, // Umbral de visibilidad del 70%
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        var h2spanElement = document.querySelectorAll('.equipo h2 span');
                        var tiempoTransicion = 70;
                        for (let i = 0; i < h2spanElement.length; i++) {
                            setTimeout(() => {
                                h2spanElement[i].classList.add('mostrar');
                            }, i * tiempoTransicion);
                        }
                    }, 100);

                    setTimeout(() => {
                        var h2activo = document.querySelectorAll('.equipo h2');
                        for (let i = 0; i < h2activo.length; i++) {
                            h2activo[i].classList.add("active");
                        }
                    }, 1000);
                }
            });
        }, options);

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.3, // Umbral de visibilidad del 70%
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        var h2spanElement = document.querySelectorAll('.equipo .part1-1 h3 span');
                        var tiempoTransicion = 70;
                        for (let i = 0; i < h2spanElement.length; i++) {
                            setTimeout(() => {
                                h2spanElement[i].classList.add('mostrar');
                            }, i * tiempoTransicion);
                        }
                    }, 100);

                    setTimeout(() => {
                        var h2activo = document.querySelectorAll('.equipo .part1-1 h3');
                        for (let i = 0; i < h2activo.length; i++) {
                            h2activo[i].classList.add("active");
                        }
                    }, 1000);
                }
            });
        }, options);

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, []);


    return <>
        <section className='equipo' id='equipo'>
            <div className='container' ref={targetRef}>
                <div className='part1' ref={parentRef}>
                    <div className='title' ref={movingElementRef}>
                        <h2 className='mensajeEspanol'>
                            <span>Nuestro</span>
                            <br />
                            <span>Equipo</span>
                            <span>&nbsp;de</span>
                            <span>&nbsp;trabajo</span>
                        </h2>
                        <h2 className='ocultar mensajeIngles'>
                            <span> Our work</span>
                            <br />
                            <span> team</span>
                        </h2>
                    </div>
                </div>
                <div className='part1-1' ref={targetRef}>
                    <h3 className='mensajeEspanol'><span>Nuestro</span><span>&nbsp;Equipo</span><span>&nbsp;de</span><span>&nbsp;trabajo</span></h3>
                    <h3 className='ocultar mensajeIngles'><span>Our</span><span>&nbsp;work</span><span>&nbsp;team</span></h3>
                </div>
                <div className='part2'>
                    <div className='listEquipo'>
                        <div className='itemEquipo'>
                            <div className='perfil'>
                                <img src={perfil1} alt="perfil1" />
                            </div>
                            <div className='datos'>
                                <div className='datos1'>
                                    <img src={perfil1} alt="perfil1" />
                                    <div className='datos2'>
                                        <h3>Alonso Montoya</h3>
                                        <p><FormattedMessage id="equipo_cargo1" /></p>
                                        <a href='https://www.linkedin.com/in/alonso-montoya-n%C3%BA%C3%B1ez-del-prado-6611245a/' target='_blank'><img src={linkedin1} alt="perfil1" /></a>
                                    </div>
                                </div>
                                <div className='desplegable'>
                                    <p> Alonso es nuestro Director de proyectos con más de 8 años de experiencia liderando el desarrollo de marcas con propósitos únicos y apiracionales.</p>
                                </div>
                            </div>
                            <div className='flecha'>
                                <img src={flecha} alt="flecha" />
                            </div>
                        </div>
                        <div className='itemEquipo'>
                            <div className='perfil'>
                                <img src={perfil2} alt="perfil1" />
                            </div>
                            <div className='datos'>
                                <div className='datos1'>
                                    <img src={perfil2} alt="perfil1" />
                                    <div className='datos2'>
                                        <h3>Francesca Noriega</h3>
                                        <p><FormattedMessage id="equipo_cargo2" /></p>
                                        <a href='https://pe.linkedin.com/in/francesca-noriega-revoredo-09a61866' target='_blank'><img src={linkedin1} alt="perfil2" /></a>
                                    </div>
                                </div>
                                <div className='desplegable'>
                                    <p>Francesca es el enlace estratégico que construye relaciones sólidas con nuestros clientes. Con su habilidad para comprender las necesidades comerciales y ofrecer soluciones personalizadas, asegura la satisfacción del cliente y fomenta asociaciones a largo plazo.</p>
                                </div>
                            </div>
                            <div className='flecha'>
                                <img src={flecha} alt="flecha" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default Equipo;