import React, { useEffect, useRef } from 'react';
import icono1 from '../icons/icono1.svg';
import icono1solo from '../icons/icono1solo.svg';
import icono2 from '../icons/icono2.svg';
import icono2solo from '../icons/icono2solo.svg';
import flecharoja from '../icons/flecha-roja.svg';
import flechaNegra from '../icons/flechita2.svg'
import { FormattedMessage } from 'react-intl';

const Portafolio = () => {

    function Mostrar(e) {
        console.log(e.target);
        e.target.parentElement.classList.toggle("mostrar");
    }


    function Mostrar2(e) {

        var elementos = document.querySelectorAll("section.servicios .container .item-services .img_services");
        if (e.target.parentElement.parentElement.classList.contains("abierto")) {
            e.target.parentElement.parentElement.classList.remove("abierto");
        } else {
            for (let i = 0; i < elementos.length; i++) {
                elementos[i].parentElement.classList.remove("abierto");
            }
            e.target.parentElement.parentElement.classList.add("abierto");
        }
    }

    /* codigo de animacion del titulo */

    setTimeout(() => {
        var h2Element = document.querySelectorAll('.servicios h2');

        for (let i = 0; i < h2Element.length; i++) {
            var textoH2 = h2Element[i].textContent;
            var palabras = textoH2.split('');
            var nuevoContenido = '';
            palabras.forEach(function (palabra) {
                h2Element[i].classList.add('mostrar');
                nuevoContenido += '<span>' + palabra + '</span> ';
                nuevoContenido = nuevoContenido.trim();
            });
            h2Element[i].innerHTML = nuevoContenido.trim();
        }
    }, 100);

    const targetRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1, // Umbral de visibilidad del 10%
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        var h2spanElement = document.querySelectorAll('.servicios h2 span');
                        var tiempoTransicion = 70;
                        for (let i = 0; i < h2spanElement.length; i++) {
                            setTimeout(() => {
                                h2spanElement[i].classList.add('mostrar');
                            }, i * tiempoTransicion);
                        }
                    }, 100);

                    setTimeout(() => {
                        var h2activo = document.querySelectorAll('.servicios h2');
                        for (let i = 0; i < h2activo.length; i++) {
                            h2activo[i].classList.add("active");
                        }
                    }, 1000);
                }
            });
        }, options);

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, []);

    /* codigo de animacion del titulo */

    /* codigo de animacion del subtitulo */


    setTimeout(() => {
        var h3Element = document.querySelectorAll('.servicios h3');

        for (let i = 0; i < h3Element.length; i++) {
            var textoH3 = h3Element[i].textContent;
            var palabrassub = textoH3.split(' ');
            var nuevoContenidosub = '';
            palabrassub.forEach(function (palabrassub) {
                h3Element[i].classList.add('mostrar');
                nuevoContenidosub += '<span>' + palabrassub + '</span> ';
            });
            h3Element[i].innerHTML = nuevoContenidosub.trim();
        }
    }, 100);

    const targetRefsub = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Umbral de visibilidad del 50%
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        var h3spanElement = document.querySelectorAll('.servicios h3 span');
                        var tiempoTransicion = 70;
                        for (let i = 0; i < h3spanElement.length; i++) {
                            setTimeout(() => {
                                h3spanElement[i].classList.add('mostrar');
                            }, i * tiempoTransicion);
                        }
                    }, 100);

                    setTimeout(() => {
                        var h3activo = document.querySelectorAll('.servicios h3');
                        for (let i = 0; i < h3activo.length; i++) {
                            h3activo[i].classList.add("active");
                        }
                    }, 1000);
                }
            });
        }, options);

        if (targetRefsub.current) {
            observer.observe(targetRefsub.current);
        }
        return () => {
            observer.disconnect();
        };
    }, []);

    /* codigo de animacion del subtitulo */



    return <>
        <section className="servicios" id='servicios'>
            <div className='container' ref={targetRef}>
                <h2 className='mensajeEspanol'>Servicios</h2>
                <h2 className='ocultar mensajeIngles'>Services</h2>
                <div className='listServices'>
                    <div class="item-services" id='serviciouno'>
                        <div class="img_services" onClick={Mostrar2} ref={targetRefsub}>
                            <a href='#serviciouno'></a>
                            <div className='imgIcon'>
                                <img src={icono1solo} alt="logo" />
                            </div>
                            <h3 className='mensajeEspanol'>Branding Diseño</h3>
                            <h3 className='ocultar mensajeIngles'>Branding Design</h3>
                            <img src={flechaNegra} alt="flecha" className='flecha' />
                        </div>
                        <div class="info_services" ref={targetRefsub}>
                            <ul>
                                <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true"><FormattedMessage id="servicios_servicio1_item1" /></li>
                                <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true"><FormattedMessage id="servicios_servicio1_item2" /></li>
                                <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true"><FormattedMessage id="servicios_servicio1_item3" /></li>
                                <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true"><FormattedMessage id="servicios_servicio1_item4" /></li>
                                <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true"><FormattedMessage id="servicios_servicio1_item5" /></li>
                                <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true"><FormattedMessage id="servicios_servicio1_item6" /></li>
                                <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true"><FormattedMessage id="servicios_servicio1_item7" /></li>
                                <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true"><FormattedMessage id="servicios_servicio1_item8" /></li>
                                <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true"><FormattedMessage id="servicios_servicio1_item9" /></li>
                            </ul>
                            <div className='texto'>
                                <p className='mensajeEspanol'>Creemos en las <b>marcas con propósito</b> que resultan relevantes para sus clientes. <b>Nada es aleatorio.</b> Vamos más alla del diseño y creamos marcas perdurables que sirven de modelo a los negocios de nuestros clientes.</p>
                                <p className='ocultar mensajeIngles'>We believe in purposeful brands that are relevant to their customers. Nothing is random. We go beyond design and create enduring brands that serve as models for our clients' businesses.</p>
                            </div>
                            <button onClick={Mostrar} data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true"><img src={flecharoja} alt="flecha" /><span className='español'><FormattedMessage id="servicios_button" /></span><span className='ingles'><FormattedMessage id="servicios_button2" /></span></button>
                        </div>
                    </div>
                    <div class="item-services" id='serviciodos'>
                        <div class="img_services" onClick={Mostrar2}>
                            <a href='#serviciodos'></a>
                            <div className='imgIcon'>
                                <img src={icono2solo} alt="logo" />
                            </div>
                            <h3><FormattedMessage id="servicios_servicio2" /></h3>
                            <img src={flechaNegra} alt="flecha" className='flecha' />
                        </div>
                        <div class="info_services">
                            <ul>
                                <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true"><FormattedMessage id="servicios_servicio2_item1" /></li>
                                <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true"><FormattedMessage id="servicios_servicio2_item2" /></li>
                                <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true"><FormattedMessage id="servicios_servicio2_item3" /></li>
                                <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true"><FormattedMessage id="servicios_servicio2_item4" /></li>
                                <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true"><FormattedMessage id="servicios_servicio2_item5" /></li>
                                <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true"><FormattedMessage id="servicios_servicio2_item6" /></li>
                                <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true"><FormattedMessage id="servicios_servicio2_item7" /></li>
                                <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true"><FormattedMessage id="servicios_servicio2_item8" /></li>
                                <li data-aos="fade-up" data-aos-duration="500" data-aos-delay="600" data-aos-once="true"><FormattedMessage id="servicios_servicio2_item9" /></li>
                            </ul>
                            <div className='texto'>
                                <p className='mensajeEspanol'><b>Desarrollamos experiencias digitales</b> para trasladar la expresión de una marca a cualquier dispotivo. Analizamos usuarios, comportamientos y tendencias para crear <b>sitios webs increibles.</b></p>
                                <p className='ocultar mensajeIngles'>We develop digital experiences to transfer the expression of a brand to any device. We analyze users, behaviors and trends to create incredible websites.</p>
                            </div>
                            <button onClick={Mostrar}><img src={flecharoja} alt="flecha" /><span className='español'><FormattedMessage id="servicios_button" /></span><span className='ingles'><FormattedMessage id="servicios_button2" /></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default Portafolio;