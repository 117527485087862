import React from 'react';
import facebook from '../icons/facebook.svg'
import instagram from '../icons/instagram.svg'
import whatsapp from '../icons/whatsapp.svg'
import atenas from '../icons/logo-atenas.svg'
import corazon from '../icons/corazon2.svg'
import {FormattedMessage} from 'react-intl'

const Pie = () => {
    return <>
        <section className='pie'>
            <div className='container'>
                <div className='part1'>
                    <p><FormattedMessage id="pie_derechos" /></p>       
                </div>
                <div className='part2'>
                    <a href='#' target='_blank'><img src={facebook} alt="img1" /></a>
                    <a href='#' target='_blank'><img src={instagram} alt="img2" /></a>
                    <a href='#' target='_blank'><img src={whatsapp} alt="img3" /></a>                    
                </div>
                <div className='part3'>
                <p>Made with<img src={corazon} className="corazon" alt=""/><img src={atenas} className="atenas" alt=""/></p>
                </div>
            </div>
        </section>
        
    </>
}

export default Pie;