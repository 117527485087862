import React, { useEffect, useRef } from 'react';
import Marquee from 'react-fast-marquee'
import xinxing from '../images/xinxing.jpg'
import lamar from '../images/lamar2.jpg'
import wedding from '../images/wedding2.jpg'
import vet from '../images/vet.jpg'
import myc from '../images/myc.jpg'
import torres from '../images/torres.jpg'
import { FormattedMessage } from 'react-intl';

const Clientes = () => {

    setTimeout(() => {
        var h2Element = document.querySelectorAll('.clientes h2');

        for (let i = 0; i < h2Element.length; i++) {
            var textoH2 = h2Element[i].textContent;
            var letras = textoH2.split('');
            var nuevoContenido = '';
            letras.forEach(function (letra) {
                h2Element[i].classList.add('mostrar');
                nuevoContenido += '<span>' + letra + '</span> ';
                nuevoContenido = nuevoContenido.trim();
            });
            h2Element[i].innerHTML = nuevoContenido.trim();
        }
    }, 100);

    const targetRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Umbral de visibilidad del 50%
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        var h2spanElement = document.querySelectorAll('.clientes h2 span');
                        var tiempoTransicion = 70;
                        for (let i = 0; i < h2spanElement.length; i++) {
                            setTimeout(() => {
                                h2spanElement[i].classList.add('mostrar');
                            }, i * tiempoTransicion);
                        }
                    }, 100);

                    setTimeout(() => {
                        var h2activo = document.querySelectorAll('.clientes h2');
                        for (let i = 0; i < h2activo.length; i++) {
                            h2activo[i].classList.add("active");
                        }
                    }, 1000);
                }
            });
        }, options);

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, []);


    return <>
        <section className='clientes' id='clientes'>
            <div className='container' ref={targetRef}>
                <h2 className='mensajeEspanol'>Clientes</h2>
                <h2 className='ocultar mensajeIngles'>Customers</h2>
                <p data-aos="fade-up" data-aos-duration="500" data-aos-delay="300" data-aos-once="true"><FormattedMessage id="clientes_texto" /></p>
            </div>
            <div className='marquesina'>
                <Marquee speed={100}>
                    <div>
                        <img src={xinxing} alt="xinxing" />
                    </div>
                    <div>
                        <img src={lamar} alt="lamar" />
                    </div>
                    <div>
                        <img src={wedding} alt="wedding" />
                    </div>
                    <div>
                        <img src={vet} alt="vet" />
                    </div>
                    <div>
                        <img src={myc} alt="myc" />
                    </div>
                    <div>
                        <img src={torres} alt="torres" />
                    </div>
                    <div>
                        <h3><FormattedMessage id="texto_marquee1" /></h3>
                    </div>
                    <div>
                        <img src={xinxing} alt="xinxing" />
                    </div>
                    <div>
                        <img src={lamar} alt="lamar" />
                    </div>
                    <div>
                        <img src={wedding} alt="wedding" />
                    </div>
                    <div>
                        <img src={vet} alt="vet" />
                    </div>
                    <div>
                        <img src={myc} alt="myc" />
                    </div>
                    <div>
                        <img src={torres} alt="torres" />
                    </div>
                    <div>
                        <h3><FormattedMessage id="texto_marquee2" /></h3>
                    </div>
                </Marquee>
            </div>
        </section>
    </>
}

export default Clientes;