import React, { useEffect, useRef } from 'react';
import estatua from '../images/estatua.webp'
import bola from '../icons/bola.svg'
import rombo2 from '../icons/calltoaction_3.svg'
import { FormattedMessage } from 'react-intl'

const Contacto = () => {

    const submitForm = (e) => {

        e.preventDefault();
        var valorFullNameContact = 0;
        var valortelephoneContact = 0;
        var valoremailContact = 0;

        if (document.querySelector("#full-name").value.length > 0) {
            valorFullNameContact = 1;
            document.querySelector("#full-name").parentElement.classList.remove("error");
        } else {
            document.querySelector("#full-name").parentElement.classList.add("error");
        }

        if (document.querySelector("#telephone").value.length > 0) {
            valortelephoneContact = 1;
            document.querySelector("#telephone").parentElement.classList.remove("error");
        } else {
            document.querySelector("#telephone").parentElement.classList.add("error");
        }

        if (document.querySelector("#email").value.length > 0) {
            valoremailContact = 1;
            document.querySelector("#email").parentElement.classList.remove("error");
        } else {
            document.querySelector("#email").parentElement.classList.add("error");
        }

        var result2 = valorFullNameContact * valortelephoneContact * valoremailContact;

        if (result2 == 0) {
            e.preventDefault();
        } else {
            document.querySelector("#fs-frm").submit();
        }
    }

    window.addEventListener('click', function (e) {

        if (e.target.closest("#full-name")) {
            document.querySelector("#full-name").parentElement.classList.add("focusin");
        } else {
            document.querySelector("#full-name").parentElement.classList.remove("focusin");
            if (document.querySelector("#full-name").value.length > 0) {
                document.querySelector("#full-name").parentElement.classList.add("ok");
            } else {
                document.querySelector("#full-name").parentElement.classList.remove("ok");
            }
        }
        if (e.target.closest("#telephone")) {
            document.querySelector("#telephone").parentElement.classList.add("focusin");
        } else {
            document.querySelector("#telephone").parentElement.classList.remove("focusin");
            if (document.querySelector("#telephone").value.length > 0) {
                document.querySelector("#telephone").parentElement.classList.add("ok");
            } else {
                document.querySelector("#telephone").parentElement.classList.remove("ok");
            }
        }
        if (e.target.closest("#email")) {
            document.querySelector("#email").parentElement.classList.add("focusin");
        } else {
            document.querySelector("#email").parentElement.classList.remove("focusin");
            if (document.querySelector("#email").value.length > 0) {
                document.querySelector("#email").parentElement.classList.add("ok");
            } else {
                document.querySelector("#email").parentElement.classList.remove("ok");
            }
        }
    })

    document.addEventListener("keydown", function (event) {
        if (event.keyCode == 9) {
            document.querySelector("#full-name").addEventListener('focusin', (event) => {
                document.querySelector("#full-name").parentElement.classList.add("focusin");
            });
            document.querySelector("#full-name").addEventListener('focusout', (event) => {
                document.querySelector("#full-name").parentElement.classList.remove("focusin");
                if (document.querySelector("#full-name").value.length > 0) {
                    document.querySelector("#full-name").parentElement.classList.add("ok");
                } else {
                    document.querySelector("#full-name").parentElement.classList.remove("ok");
                }
            });
            document.querySelector("#telephone").addEventListener('focusin', (event) => {
                document.querySelector("#telephone").parentElement.classList.add("focusin");
            });
            document.querySelector("#telephone").addEventListener('focusout', (event) => {
                document.querySelector("#telephone").parentElement.classList.remove("focusin");
                if (document.querySelector("#telephone").value.length > 0) {
                    document.querySelector("#telephone").parentElement.classList.add("ok");
                } else {
                    document.querySelector("#full-name").parentElement.classList.remove("ok");
                }
            });
            document.querySelector("#email").addEventListener('focusin', (event) => {
                document.querySelector("#email").parentElement.classList.add("focusin");
            });
            document.querySelector("#email").addEventListener('focusout', (event) => {
                document.querySelector("#email").parentElement.classList.remove("focusin");
                if (document.querySelector("#email").value.length > 0) {
                    document.querySelector("#email").parentElement.classList.add("ok");
                } else {
                    document.querySelector("#mail-home").parentElement.classList.remove("ok");
                }
            });
        }
    })

    function handleChange() {
        if (document.querySelector("#full-name").value.length > 0) {
            document.querySelector("#full-name").parentElement.classList.add("ok");
        } else {
            document.querySelector("#full-name").parentElement.classList.remove("ok");
        }
        if (document.querySelector("#telephone").value.length > 0) {
            document.querySelector("#telephone").parentElement.classList.add("ok");
        } else {
            document.querySelector("#telephone").parentElement.classList.remove("ok");
        }
        if (document.querySelector("#email").value.length > 0) {
            document.querySelector("#email").parentElement.classList.add("ok");
        } else {
            document.querySelector("#email").parentElement.classList.remove("ok");
        }
    }

    function myFunction2() {
        var listaImagenes = document.querySelectorAll(".contacto .imageDistorcion");
        var tiempoTransicion = 70;

        for (let i = 0; i < listaImagenes.length; i++) {
            setTimeout(() => {
                listaImagenes[i].style.opacity = 1;
            }, i * tiempoTransicion);
            if (i !== listaImagenes.length - 1) {
                setTimeout(() => {
                    listaImagenes[i + 1].style.opacity = 1;
                    setTimeout(() => {
                        listaImagenes[i].style.opacity = 0;
                    }, (i) * 20);
                }, (i + 0.2) * tiempoTransicion);
            } else {
                listaImagenes[i].style.opacity = 0;
            }
        }
    }

    function distorcion() {
        myFunction2()
    }

    const mediaQuery = window.matchMedia('(max-width: 1200px)');

    if (mediaQuery.matches) {
        setTimeout(function () {
            myFunction2()
            setInterval(function () {
                myFunction2()
            }, 5000);
        }, 2000);
    } else {
    }

    setTimeout(() => {
        var h2Element = document.querySelectorAll('.contacto h2');
        var spanElement = document.querySelectorAll('.contacto h2 span');

        for (let i = 0; i < h2Element.length; i++) {
            h2Element[i].classList.add('mostrar');
            /*
            var textoH2 = h2Element[i].textContent;
            var palabras = textoH2.split('');
            var nuevoContenido = '';
            palabras.forEach(function (palabra) {
                nuevoContenido += '<span>' + palabra + '</span> ';
                nuevoContenido = nuevoContenido.trim();
            });
            h2Element[i].innerHTML = nuevoContenido.trim();
            */
        }
    }, 100);

    const targetRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Umbral de visibilidad del 50%
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        var h2spanElement = document.querySelectorAll('.contacto h2 span');
                        var tiempoTransicion = 70;
                        for (let i = 0; i < h2spanElement.length; i++) {
                            setTimeout(() => {
                                h2spanElement[i].classList.add('mostrar');
                            }, i * tiempoTransicion);
                        }
                    }, 100);

                    setTimeout(() => {
                        var h2activo = document.querySelectorAll('.contacto h2');
                        for (let i = 0; i < h2activo.length; i++) {
                            h2activo[i].classList.add("active");
                        }
                    }, 1000);
                }
            });
        }, options);

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, []);

    return <>
        <section className='contacto' id='contactoPage'>
            <div className='container'>
                <div className='content' ref={targetRef}>
                    <h2 className='mensajeEspanol'>
                        <span>¡</span>
                        <span>E</span>
                        <span>s</span>
                        <span>c</span>
                        <span>r</span>
                        <span>í</span>
                        <span>b</span>
                        <span>e</span>
                        <span>n</span>
                        <span>o</span>
                        <span>s</span>
                        <span>!</span>
                    </h2>
                    <h2 className='ocultar mensajeIngles'>
                        <span>Write</span>
                        <span>&nbsp;to</span>
                        <span>&nbsp;us</span>
                    </h2>
                    <p data-aos="fade-up" data-aos-duration="500" data-aos-delay="800" data-aos-once="true"><FormattedMessage id="contacto_bajada" /> <b>hola@atenas.media</b> <FormattedMessage id="contacto_bajada2" /></p>
                    <img src={bola} alt="img-contacto2" className='bola'/>
                    <img src={rombo2} alt="img-contacto2-1" className='ancho' />
                    <div className='formulario'>
                        <form acceptCharset="utf-8" action="php/thanks.php" id='fs-frm' method="post">
                            <fieldset>
                                <div className="form-group">
                                    <input type="text" name="full-name" id='full-name' placeholder="" className="form-input" onChange={handleChange} />
                                    <label htmlFor="full-name" className="form-label"><FormattedMessage id="home_contacto_label_nombre" /></label>
                                </div>
                                <div className="form-group">
                                    <input type="mail" name="email" id='email' placeholder="" className="form-input" onChange={handleChange} />
                                    <label htmlFor="email" className="form-label"><FormattedMessage id="home_contacto_label_Email" /></label>
                                </div>
                                <div className="form-group">
                                    <input type="number" name="celular" id='telephone' placeholder="" className="form-input" onChange={handleChange} />
                                    <label htmlFor="telephone" className="form-label"><FormattedMessage id="home_contacto_label_Celular" /></label>
                                </div>
                                <div class="buttonColor">
                                    <button className="submit" onClick={submitForm}><FormattedMessage id="home_contacto_label_enviar" /></button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <div className='cabezafloat' onMouseEnter={distorcion}>
                <img src={estatua} alt="img2" className='imageDistorcion img1 estatua' />
                <img src={estatua} alt="img2" className='imageDistorcion img2 estatua' />
                <img src={estatua} alt="img2" className='imageDistorcion img3 estatua' />
                <img src={estatua} alt="img2" className='imageDistorcion img4 estatua' />
                <img src={estatua} alt="img2" className='imageDistorcion img5 estatua' />
                <img src={estatua} alt="img2" className='imageDistorcion img6 estatua' />
                <img src={estatua} alt="img2" className='imageDistorcion img7 estatua' />
                <img src={estatua} alt="img2" className='imageDistorcion img8 estatua' />
                <img src={estatua} alt="img2" className='imageDistorcion img9 estatua' />
                <img src={estatua} alt="img2" className='imageDistorcion img10 estatua' />
            </div>
        </section>
    </>
}

export default Contacto;