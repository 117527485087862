import React, { useEffect, useRef } from 'react';
import img2 from '../icons/calltoaction_1.svg'
import img3 from '../icons/calltoaction_3.svg'
import img4 from '../icons/calltoaction_2.svg'
import img5 from '../icons/calltoaction_4.svg'
import img6 from '../icons/calltoaction_5.svg'
import { FormattedMessage } from 'react-intl';

const Banner = () => {
    function close() {
        document.querySelector("#popFormulario").classList.toggle("open");
    }

    setTimeout(() => {
        var h2Element = document.querySelectorAll('.banner h2');

        for (let i = 0; i < h2Element.length; i++) {
            var textoH2 = h2Element[i].textContent;
            var palabras = textoH2.split(' ');
            var nuevoContenido = '';
            palabras.forEach(function (palabra) {
                h2Element[i].classList.add('mostrar');
                nuevoContenido += '<span>' + palabra + '</span> ';
            });
            h2Element[i].innerHTML = nuevoContenido.trim();
        }
    }, 100);

    const targetRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.7, // Umbral de visibilidad del 70%
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        var h2spanElement = document.querySelectorAll('.banner h2 span');
                        var tiempoTransicion = 70;
                        for (let i = 0; i < h2spanElement.length; i++) {
                            setTimeout(() => {
                                h2spanElement[i].classList.add('mostrar');
                            }, i * tiempoTransicion);
                        }
                    }, 100);

                    setTimeout(() => {
                        var h2activo = document.querySelectorAll('.banner h2');
                        for (let i = 0; i < h2activo.length; i++) {
                            h2activo[i].classList.add("active");
                        }
                    }, 1000);
                }
            });
        }, options);

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, []);


    return <>
        <section className="banner">
            <div className='container'>
                <div className='part1' ref={targetRef}>
                    <h2 className='mensajeEspanol'>¡Contáctanos ahora!</h2>
                    <h2 className='ocultar mensajeIngles'>¡Contact us now!</h2>
                    <p data-aos="fade-up" data-aos-duration="500" data-aos-delay="1000" data-aos-once="true"><FormattedMessage id="banner_texto" /></p>
                    <img src={img2} alt="img1" className='img1' />
                </div>
                <div className='part2'>
                    <div className='buttonColor'>
                        <a href='#contactoPage' data-aos="fade-up" data-aos-duration="500" data-aos-delay="1000" data-aos-once="true"><FormattedMessage id="button_contacto" /></a>
                    </div>
                    <button onClick={close} data-aos="fade-up" data-aos-duration="500" data-aos-delay="1000" data-aos-once="true"><FormattedMessage id="button_contacto" /></button>
                </div>
            </div>
            <img src={img5} alt="img5" className='img5' />
            <img src={img4} alt="img4" className='img4' />
            <img src={img3} alt="img4" className='img2' />
            <img src={img6} alt="img4" className='img6' />
        </section>
    </>
}

export default Banner;