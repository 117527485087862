import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import autor from '../images/autor.jpg'
import autorSamuel from '../images/samuel.jpg'
import comillas from '../icons/comillas.svg'
import { FormattedMessage } from 'react-intl'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

const Testimonios = () => {

    setTimeout(() => {
        var h2Element = document.querySelectorAll('.testimonios h1');
        var spanElement = document.querySelectorAll('.testimonios h1 span');

        for (let i = 0; i < h2Element.length; i++) {
            var textoH2 = h2Element[i].textContent;
            var palabras = textoH2.split('');
            var nuevoContenido = '';
            palabras.forEach(function (palabra) {
                h2Element[i].classList.add('mostrar');
                nuevoContenido += '<span>' + palabra + '</span> ';
                nuevoContenido = nuevoContenido.trim();
            });
            h2Element[i].innerHTML = nuevoContenido.trim();
        }
    }, 100);

    const targetRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Umbral de visibilidad del 50%
        };

        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        var h2spanElement = document.querySelectorAll('.testimonios h1 span');
                        var tiempoTransicion = 70;
                        for (let i = 0; i < h2spanElement.length; i++) {
                            setTimeout(() => {
                                h2spanElement[i].classList.add('mostrar');
                            }, i * tiempoTransicion);
                        }
                    }, 100);

                    setTimeout(() => {
                        var h2activo = document.querySelectorAll('.testimonios h1');
                        for (let i = 0; i < h2activo.length; i++) {
                            h2activo[i].classList.add("active");
                        }
                    }, 1000);
                }
            });
        }, options);

        if (targetRef.current) {
            observer.observe(targetRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, []);

    return <>
        <section className='testimonios' id='testimonios'>
            <div className='container' ref={targetRef}>
                <h1 className='mensajeEspanol'>Testimonios</h1>
                <h1 className='ocultar mensajeIngles'>Testimonials</h1>
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    //autoplay={{ delay: 5000, disableOnInteraction: false, }}
                    loop={true}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    data-aos="fade-up" data-aos-duration="500" data-aos-delay="700" data-aos-once="true"
                >
                    <SwiperSlide>
                        <div className='texto'>
                            <img src={comillas} className='comillas' alt="comillas" />
                            <p>La elección de trabajar con Atenas para el desarrollo de nuestra marca ha sido un verdadero acierto. Desde el primer momento, su equipo de expertos comprendió nuestra visión y nos guió a través de un proceso de branding completo, seguido de nuestra página web. El resultado ha sido excepcional. Nuestra marca ahora destaca en el competitivo mercado del cuidado automotriz, captando la atención de clientes potenciales.”</p>
                        </div>
                        <div className='autor'>
                            <div className='imagen'>
                                <img src={autorSamuel} alt="perfil" />
                            </div>
                            <div className='datos'>
                                <h3>Samuel Carty</h3>
                                <h4><FormattedMessage id="testimonios_cargo" /> - Spray Wash</h4>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </section>
    </>
}

export default Testimonios;