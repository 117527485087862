import React, { useContext } from 'react';
import logo from '../icons/logo-atenas.svg'
import menu from '../icons/menu.svg'
import { IntlProvider, FormattedMessage } from 'react-intl';
import { langContext } from './../context/langContext';

const Navbar = () => {

    const idioma = useContext(langContext);

    window.addEventListener("scroll", function () {
        if (this.pageYOffset > 60) {
            document.querySelector("header").classList.add("sticky");
        }
        else {
            document.querySelector("header").classList.remove("sticky");
        }
    });
    
    var lastScrollTop = 0;

    window.addEventListener("scroll", function () {
        var st = window.pageYOffset || document.documentElement.scrollTop;
        if (st < lastScrollTop) {
            document.querySelector("header").classList.add("mostrar");
        }else{
            document.querySelector("header").classList.remove("mostrar");
        }
        lastScrollTop = st <= 0 ? 0 : st; 
    });

    function cambioUS() {
        idioma.establecerLenguaje('en-US')
        var btnUs = document.querySelector('button.en');
        var btnEs = document.querySelector('button.es');
        var listamensajeEspanol = document.querySelectorAll(".mensajeEspanol")
        var listamensajeIngles = document.querySelectorAll(".mensajeIngles")
        
        for (let i = 0; i < listamensajeEspanol.length; i++) {            
            listamensajeEspanol[i].classList.add("ocultar");
        }
        for (let i = 0; i < listamensajeIngles.length; i++) {            
            listamensajeIngles[i].classList.remove("ocultar");
        }
        
        btnEs.classList.remove("active");
        btnUs.classList.add("active");
    }

    function cambioES() {
        idioma.establecerLenguaje('es-ES')
        var btnUs = document.querySelector('button.en');
        var btnEs = document.querySelector('button.es');

        var listamensajeEspanol = document.querySelectorAll(".mensajeEspanol")
        var listamensajeIngles = document.querySelectorAll(".mensajeIngles")
        
        for (let i = 0; i < listamensajeEspanol.length; i++) {            
            listamensajeEspanol[i].classList.remove("ocultar");
        }
        for (let i = 0; i < listamensajeIngles.length; i++) {            
            listamensajeIngles[i].classList.add("ocultar");
        }

        btnEs.classList.add("active");
        btnUs.classList.remove("active");
    }

    function openMenu(e) {
        
        document.querySelector("header .menumobil").classList.toggle("active");
        document.querySelector("#elementos").classList.toggle("activo");
        document.querySelector("header").classList.toggle("quedarse");

        setTimeout(() => {
            document.querySelector("#menuPage").classList.toggle("open");
        }, 200);

        setTimeout(() => {
            document.querySelector("#elementos").classList.remove("activo"); 
        }, 800);

        //if (e.target.classList.contains("active")) {
          //  document.querySelector("#elementos").classList.remove("no_activo");
        //}else{      
          //  document.querySelector("#elementos").classList.add("no_activo");
        //}
    }

    return <>
        <header>
            <nav className="container">
                <div className="logo">
                    <a href="https://atenasmedia.nerddesignlab.com/"><img src={logo} alt="Logo-AMDIGITAL" /></a>
                </div>
                <div className="navigation">
                    <ul>
                        <li>
                            <a href="#servicios"><FormattedMessage id="navbar_item1" /></a>
                        </li>
                        <li className="menuPortafolio">
                            <span><FormattedMessage id="navbar_item2_sub" /></span>
                            <a href="#portafolio"><FormattedMessage id="navbar_item2" /></a>
                        </li>
                        {/*
                        <li className="idioma">
                            <button className="es idioma-btn" onClick={cambioES}>ES</button>
                            <button className="en idioma-btn" onClick={cambioUS}>EN</button>
                        </li>
                        */}
                        <li className='menumobil' onClick={openMenu}>
                            <label class="burger" htmlFor="burger">
                                <input type="checkbox" id="burger" />
                                <div></div>
                                <div></div>
                                <div></div>
                            </label>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    </>
}

export default Navbar;